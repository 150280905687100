@use "sass:color"; @use "sass:math"; @use "@/web/client/assets/css/variables.scss" as *; 
footer{
    color: #999;
    background: $dark-page;
    padding: $hspace 0;

    p{
        margin: math.div($padding, 2) 0;
    }
}
