@use "sass:color"; @use "sass:math"; @use "@/web/client/assets/css/variables.scss" as *; 
main{
    margin-top: $header-height;
}

.main-layout-header{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9;
}
